<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()">
    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
  @if (loaded$()) {
    <app-footer [domain]="domainName"></app-footer>
  }
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>

  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
